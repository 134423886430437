//
// Content
// ----------------------------------------------
#breadcrumbs {
	.breadcrumb {
		margin-bottom: 0;
		padding-bottom: 2rem;
		padding-top: 2rem;

		a, li {
			font-weight: 600;
		}

		a {
			#{$attention} {
				color: $brand-primary;
			}
		}
	}
}

.content {
	padding-bottom: 4rem;
	padding-top: 4rem;
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}