#testimonials {

	ul {
		padding-left: 20px;
		li {
			a {
				color: $brand-secondary;
				font-weight: 600;
			}
		}
	}

	.testimonial {

		hr {
			margin-bottom: 0rem;
			margin-top: 1.125rem;
			padding-bottom: 1.125rem;
			padding-top: 1.125rem;
		}

		blockquote {
			background: url('/assets/images/icons/quotes.png') left top no-repeat;
			padding-left: 3.125rem;
		}

		.testimonial-name {
			color: $brand-primary;
			font-size: 1.4375rem;
			font-weight: 600;
			line-height: 1;
			margin-bottom: 0;
			padding-left: 3.125rem;
		}

		.testimonial-company {
			color: $brand-secondary;
			font-size: 1.25rem;
			padding-left: 3.125rem;
			text-transform: uppercase;
		}
	}
}