/*
#general {
	.content {
		padding-bottom: 0;
		padding-top: 0;
	}
}

#service {
	.content {
		padding-bottom: 0;
		padding-top: 0;
	}
}
*/