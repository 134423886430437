h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 600;
	margin-top: 0;
	padding: 0;
}

h1, .h1,
h2, .h2,
h3, .h3 {
	font-weight: 600;
	margin-bottom: 2rem;
	margin-top: 0;
	letter-spacing: -1.5px;
	padding: 0;
	padding-bottom: 7px;
	text-transform: uppercase;
	position: relative;

	&::after {
		content: " ";
		border-bottom: 5px solid $brand-primary;
		position: absolute;
		left: 0;
		bottom: 0%;
		width: 100px;
	}
}

p {
    margin-top: 0;
}


a {
	color: $brand-primary;
}
