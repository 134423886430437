$owl-font-path: "./fonts/" !default;
$owl-font-family: "FontAwesome" !default;
$owl-loader-path: "./../images/" !default;
$owl-arrow-color: $brand-primary !default;
$owl-dot-color: black !default;
$owl-dot-color-active: $owl-dot-color !default;
$owl-prev-character: "\f053" !default;
$owl-next-character: "\f054" !default;
$owl-dot-character: "\f111" !default;
$owl-dot-size: 6px !default;
$owl-opacity-default: 0.75 !default;
$owl-opacity-on-hover: 1 !default;
$owl-opacity-not-active: 0.25 !default;
//
// Theme specific styles
// ==============================

// Homepage
// ==============================
#owl_carousel {
	border-bottom: 0px solid $brand-primary;
	display: none;

	.caption {
		position: absolute;
		right: 0;
		bottom: 0;
		padding-left: 25px;
		padding-right: 25px;
		padding-top: 125px;
		height: 100%;
		width:100%;
		background-color: rgba(6, 128, 113, 0.5);
		text-align: center;
		width: 500px;

		.h1 {
			color: #fff;
			font-weight: 700;
			margin-bottom: 10px;
		}

		p {
			color: #fff;
			margin-bottom: 20px;
		}
	}


}
.owl-theme {
	.owl-nav {
		margin: 0;

		.owl-prev,
		.owl-next {
		    position: absolute;
		    display: block;
		    height: 40px;
		    width: 40px;
		    line-height: 0px;
		    font-size: 0px;
		    cursor: pointer;
		    background: transparent;
		    color: transparent;
		    top: 50%;
		    left: 25px;
		    -webkit-transform: translate(0, -50%);
		    -ms-transform: translate(0, -50%);
		    transform: translate(0, -50%);
		    padding: 0;
		    border: none;
		    outline: none;

		    &:hover, &:focus {
		        outline: none;
		        background: transparent;
		        color: transparent;

		        &:before {
		            opacity: $owl-opacity-on-hover;
		        }
		    }

		    &.owl-disabled:before {
		        opacity: $owl-opacity-not-active;
		    }

		    &:before {
		        font-family: $owl-font-family;
		        font-size: 40px;
		        line-height: 1;
		        color: $owl-arrow-color;
		        opacity: $owl-opacity-default;
		        -webkit-font-smoothing: antialiased;
		        -moz-osx-font-smoothing: grayscale;
		    }
		}

		.owl-prev {
		    z-index: 1;

/*
		    &:before {
		        content: $owl-prev-character;
		    }
*/
		}

		.owl-next {
			left: auto;
			right: 25px;
/*
			&:before {
		        content: $owl-next-character;
		        color: $white;
		    }
*/
		}
	}
}

