#contact {

	#map {
		height: 375px;
		margin-bottom: 2.1875rem;
	}

	.content {
		padding-top: 0;

		#contact_details {
			margin-bottom: 1.5625rem;

			ul {
				li {
					color: $black;
					margin-left: 1.4375rem;

					&.address, &.phone, &.email {
						margin-left: 0px;
					}

					&.phone, &.email {
						margin-bottom: 0.75rem;
						margin-top: 6px;
					}

					i.fa {
						color: $brand-primary;
						margin-right: 4px;
						width: 0.9375rem;
					}

					a {
						color: $black;

						span {
							color: $black;
							font-weight: bold;
						}
					}
				}
			}
		}

		#contact_form {
			margin-top: 1.5625rem;

			input[required] {
				&:after {
					content: "REQ";
				}
			}

		    .g-recaptcha {
		        margin-bottom: 0.9375rem;

		        > div {
			        text-align: center;
			        width: 100%!important;
				}
		    }
		}
	}
}