#news {

	.content {
		padding-top: 0;
	}

	#news_listing {

		.news-list-article {
			margin-bottom: 2rem;

			img {
				margin-bottom: 1.5625rem;
			}

			h2 {
				font-size: 1.5rem;
				margin-bottom: 1rem;

				&:after {
					border-bottom: none;
				}

				a {
					color: $body-color;

					#{$attention} {
						color: $body-color;
					}
				}
			}

			p {
				&.comments, &.meta {
					font-style: italic;
				}
			}
		}
	}


	article {
		margin-bottom: 1.5625rem;

		h1 {
			margin-bottom: 0.5rem;
		}

		p {
			&.meta {
				font-style: italic;

				a {
					font-weight: 600;
				}
			}
		}

		.article-image {
			margin-bottom: 1.5625rem;
		}

		div {

			&.description {
				margin-bottom: 1rem;
				margin-top: 1rem;
			}
		}
	}

	div {

		&.description {
			margin-bottom: 1rem;

			a {
				font-weight: 600;

				#{$attention} {
					color: $brand-primary;
				}
			}
		}

		&.meta {
			border-bottom: 2px solid $brand-primary;
			margin-bottom: 1.25rem;

			.cats {
				margin-bottom: 0.25rem;

				a {
					font-weight: 600;
				}
			}

			.tags {

				a {
					font-weight: 600;
				}
			}
		}
	}

	.comment-form {
		label {
			margin-bottom: 0;
		}
	}
}