//
// Header
// ----------------------------------------------

header {

	padding-bottom: 1rem;
	padding-top: 0rem;


	#logo {
		text-align: center;
	}


	#form1_search, #form2_search {
		margin-top: 18px;
		text-align: center;

		label {
			display: block;
			font-size: 0.875rem;
		}

		input {
			border: 1px solid #ced4da;
			margin-right: -9px;
			font-size: 0.875rem;
			padding: 0.375rem 0.75rem;
		}

		#form1_q {
			border-radius: 0.25rem 0 0 0.25rem;
			width: 300px;
		}

		.btn {
			border-color: $brand-primary;
			border-radius: 0 0.25rem 0.25rem 0;
			margin-top: -3px;
		}
	}

	#form2_search {
		margin-top: 0rem;
	}


	#header_contacts {
		text-align: center;

		ul {
			margin-bottom: 0;
			margin-top: 23px;

			li {

				margin-bottom: 0;

				i, svg {
					font-size: 18px;
					margin-right: 6px;
				}

				a {
					color: $text-color;
					font-size: 1.125rem;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}

.breadcrumb {
	background: none;

	>li+li:before {
		content: "/";
	}
}