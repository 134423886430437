//
// Custom fonts
// --------------------------------------------------

@import 'custom-fonts';

//
// Custom Variables
// --------------------------------------------------

@import 'custom-variables';

//
// Custom Mixins
// --------------------------------------------------

@import 'custom-mixins';

//
// Custom Bootstrap
// --------------------------------------------------

@import '~bootstrap/scss/bootstrap';

//
// Vendor
// --------------------------------------------------

@import '~cookieconsent/src/styles/base';
@import '~cookieconsent/src/styles/layout';
@import '~cookieconsent/src/styles/media';
@import '~cookieconsent/src/styles/animation';
@import '~owl.carousel/src/scss/owl.carousel';
@import '~owl.carousel/src/scss/owl.theme.default';
@import 'vendor/owl-theme';

//
// Site Theme
// --------------------------------------------------

@import 'theme/theme';
