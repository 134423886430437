//
// Base
// ----------------------------------------------

* {outline: none;}

:focus {outline:none!important;}
::-moz-focus-inner {border: 0;}

::selection {background-color: #FFA; color: #000;}
::-moz-selection {background-color: #FFA; color: #000;}

//html {overflow:scroll !important;}

body {
	background-color: $white;
    font-weight: 300;
}

//
// Generic Elements
// --------------------------------------------------

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    margin-top: 0;
}

p {
    margin-top: 0;
}

a {
    text-decoration: none;

    &:focus, &:hover {
        text-decoration: underline;
    }
}

strong {
    font-weight: 700;
}

span.amp {
    font-family: Baskerville,'Goudy Old Style',Palatino,'Book Antiqua',serif !important;
    font-style: italic;
}

textarea {
    resize: vertical;
}

.color--facebook {
    color: $brand-facebook;
}

.color--twitter {
    color: $brand-twitter;
}

//
// Remove gutters from element
// --------------------------------------------------

.row {

    &--gutterless {

        & > [class^="col-"],
        & > [class*=" col-"] {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

.gutterless {

    &--left {
        padding-left: 0;
    }

    &--right {
        padding-right: 0;
    }
}