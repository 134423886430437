//
// Navbars
// --------------------------------------------------

.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
	max-height: none;
}

#mobile_nav {

	background-color: $brand-secondary;
	margin-bottom: 1rem;
	//padding-bottom: 1rem;
	padding-top: 1rem;

	ul {
		margin: 0;
		padding-bottom: 1rem;
		width: 100%;

		li {
			margin: 0;
			text-align: center;
			width: 15%;
			i, svg {
				font-size: 1.2rem;
			}
		}
	}

	#mobile_menu.navbar-collapse {

		ul.navbar-nav {

			li.nav-item {

				position: relative;
				color: $brand-primary;
				text-align: left;
				width: 100%;


				.caret {
					animation: minus 0.5s;
					position: absolute;
					right: 35px;
					top: -1px;

				}

				ul.dropdown-menu {
					border-radius: 0;
					padding-bottom: 0;
					padding-top: 0;

					li.nav-item {

						background-color: $brand-primary;
						border-bottom: none;

						#{$attention} {
							background: $brand-primary;
						}

						a {
							color: $black;
						}

						ul.dropdown-menu {
							border: none;
							li.nav-item {
								border: none;
							}
						}
					}
				}
			}
		}
	}
}

.navbar {
	background-color: $black;
	border: none;
	margin-bottom: 0;
	//min-height: 2.5rem;
	padding: 0;

	.navbar-toggler {
		border-color: $brand-primary;
		margin-bottom: 0;
		margin-left: auto;
		margin-right: auto;
		padding-left: 0.15rem;
		padding-right: 0.15rem;
		padding-bottom: 0.15rem;
		padding-top: 0.15rem;
		text-align: center;

		.navbar-toggler-icon {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(39, 169, 225, 1.0)' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
		}
	}


	.navbar-collapse {
		border: $black;
		//margin-bottom: 0.9375rem;
		padding-left: 0;
		padding-right: 0;

		.navbar-nav {
			background-color: $black;
			margin: 0;

			> li {
				padding-left: 0.9375rem;
				padding-right: 0.9375rem;

				a {
					display: inline-block;
				}

				span {
					color: $white;
					display: inline-block;

					svg,i {
						color: $white;
						position: absolute;
						right: 0.5rem;
						font-size: 0.875rem;
						-webkit-animation: minus 0.5s;
						animation: minus 0.5s;
						top: 0.825rem;
					}

					&[aria-expanded="false"] > .expanded, &[aria-expanded="true"] > .collapsed {display: none;}

					&.dropdown-toggle {
						&::after {
							border: none;
						}
					}
				}

				#{$attention} {
					background: $brand-primary;

					a {
						color: $white;

						#{$attention} {
							i, svg {
								color: $white;
							}
						}
					}
				}

				a {
					color: $white;
					text-transform: uppercase;
				}
			}

			> .active {
				background: $brand-primary;
				color: #FFF;

				#{$attention} {
					background: $brand-primary;
					color: #FFF;
				}

				> a {
					background: none;
					color: $white;
				}
			}
		}
	}
}