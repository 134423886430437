//
// Footer
// ----------------------------------------------

section {

	&#footer_upper {
		background-color: $brand-primary;

		padding-bottom: 3rem;
		padding-top: 3rem;

		h2, p.h2 {
			color: $white;
		}


		#footer_address {
			color: $white;
			font-size: 0.9375rem;

			ul {
				margin-bottom: 0;
				margin-top: 1rem;

				.phone {
					margin-top: 1rem;
				}

				a {
					color: $white;
					font-size: 0.9375rem;
				}
			}
		}

		ul {
			&#quick_links {
				padding-left: 15px;
				li {
					color: $white;

					a {
						color: $white;
					}
				}
			}
		}



		#footer_social {
			line-height: 38px;
			text-align: center;

			ul {
				margin-bottom: 0;
			}
		}


		#quick_contact_form {
			margin-bottom: 25px;

			label {
				color: $white;
			}

			input, textarea {
				border-radius: 0;
			}

			input {
				&.form-check-input {
					margin-top: 0.45rem;
				}
			}


		    .g-recaptcha {
		        margin-bottom: 0.9375rem;

		        > div {
			        text-align: center;
			        width: 100%!important;
				}
		    }

			.btn {
				background-color: $brand-tertiary;
				border-radius: 0;
				text-align: center;
				width: 100%;
			}
		}

	}

}

footer {
	background: $black;
	color: $white;
	padding-bottom: 1rem;
	padding-top: 1rem;


	#copyright {
		line-height: 2.375rem;
		text-align: center;

		p {
			margin: 0;
		}
	}

	#built_by {text-align: center;}
}