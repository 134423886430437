//
// Homepage
// ----------------------------------------------
#home {

	#home_news {
		background: #efefef;
		padding-bottom: 4rem;
		padding-top: 4rem;

		h2 {
			font-size: 1.5rem;
		}

		.meta {
			border-top: 1px solid lighten($brand-secondary, 50%);
			margin-top: 4px;
			padding-top: 4px;
		}
	}

}