//
// Custom Variables
// --------------------------------------------------

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$brand-primary: #27a9e1;
$brand-secondary: #0e0d1d;
$brand-tertiary: #333;

$black: #000 !default;
$grey: #F1F4F5 !default;
$white: #fff !default;

$brand-facebook: #3b5998 !default;
$brand-twitter: #4099FF !default;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Global text color on `<body>`.
$body-color: #404040 !default;
$text-color: #404040 !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$opensans: 'Open Sans', sans-serif !default;

$font-family-sans-serif: $opensans !default;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-secondary-color: $brand-primary !default;
$btn-secondary-bg: $white !default;
$btn-secondary-border: $btn-secondary-color !default;

$btn-brand-color: $white !default;
$btn-brand-bg: $brand-primary !default;
$btn-brand-border: $brand-primary !default;

$btn-facebook-color: $white !default;
$btn-facebook-bg: $brand-facebook !default;
$btn-facebook-border: $btn-facebook-bg !default;

//== Navbar
//
//##
// Basics of a navbar

$navbar-border-radius: 0 !default;


//$breadcrumb-item-padding: 0.25rem;

$owl-image-path: "/node_modules/owl.carousel/src/img/";