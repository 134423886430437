//
// Buttons
// ----------------------------------------------

// Button Sizes
// --------------------------------------------------

// Alternate buttons
// --------------------------------------------------

.btn-secondary {
	@include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}

.btn-brand {
	@include button-variant($btn-brand-bg, $btn-brand-bg, $btn-brand-border);
}

.btn-facebook {

	@include button-variant($btn-facebook-color, $btn-facebook-bg, $btn-facebook-border);

	span {
		margin-right: 0.625rem;
	}
}
