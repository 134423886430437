// Mobiles & Tablets 992px and under
@include media-breakpoint-down('lg') {

	.navbar {

		.navbar-collapse {

			.navbar-nav {

				> li {

					&.nav-item {
						a {
							font-size: 0.75rem;
						}
					}
				}
			}
		}
	}

	#news_article {

		.content {
			padding-bottom: 2rem;
			padding-top: 2rem;
		}

	}

	footer {
		padding-bottom: 0rem;
		padding-top: 2rem;

		.container {
			.row {
				> div {
					margin-bottom: 2rem;
				}
			}
		}

		#footer_text {
			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}


// Mobiles & tablets 768px and under
@include media-breakpoint-down('md') {
	.img-fluid {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	section {
		&#footer_upper {
			padding-top: 0;
			text-align: center;

			p {
				&.h2 {
					margin-bottom: 0.5rem;
					margin-top: 2rem;
					padding-bottom: 0;
				}
			}
		}
	}

	footer {
		text-align: center;
	}

	#home {
		.content {
			padding-top: 1rem;

		}
	}
}


// Mobiles 576px and under
@include media-breakpoint-down('sm') {

}





// Tablets and Desktops 992px & over
@include media-breakpoint-up('lg') {
	header {
		padding-bottom: 1.5rem;
		padding-top: 1.5rem;

		#logo {
			text-align: left;
		}

		#header_contacts {
			text-align: right;
		}
	}


	section {
		&#footer_upper {
			#footer_social {
				text-align: left;

				ul {
					padding-left: 15px;
				}
			}

			#quick_contact_form {
				label {
					font-size: 0.8125rem;
				}
			}
		}
	}

	footer {
		#copyright {
			margin-bottom: 0;
			text-align: left;
		}

		#built_by {text-align: right;}
	}

	#contact {
		.content {
			#contact_form {
				margin-top: 0;
			}
		}
	}
}


// Tablets and Desktops 768px & over
@include media-breakpoint-up('md') {
	.navbar {
		background: none;
		padding: 0;

		.navbar-collapse {
			margin-bottom: 0;
		}
	}

	.navbar {

		background-color: $brand-secondary;
		padding: 0;

		.navbar-collapse {

			margin-bottom: 0;

			.navbar-nav {

				> li {

					.dropdown-menu {
						padding-bottom: 0;
						padding-top: 0;

						li {
							&.nav-item {
								padding-left: 0.9375rem;
								padding-right: 0.9375rem;
							}
						}
					}

					#{$attention} {
						.dropdown-menu {
							background: $grey;
							border: none;
							border-radius: 0;
							display: block;
							margin-top: 0;
							min-width: 100%;

							li {

								.nav-link {
									padding-left: 0.75rem;
									padding-right: 0.75rem;
									text-transform: uppercase;
									white-space: nowrap;
								}

								&.dropdown-submenu {
									position: relative;

									.dropdown-menu {
										display: none;
										top: -2px;
										left: 100%;

										a {
											color: $black;
										}


									}

									#{$attention} {
										.dropdown-menu {
											display: block;

											li {
												a {
													color: $black;
													text-transform: uppercase;
													white-space: nowrap;
												}

												#{$attention} {

													a {
														color: $black;
													}
												}
											}
										}
									}

								}

								a {
									color: $black;
									text-transform: uppercase;
									white-space: nowrap;
								}

								#{$attention} {

									a {
										color: $black;
										text-transform: uppercase;
										white-space: nowrap;
									}
								}
							}
						}
					}


				}
			}
		}
	}
}


